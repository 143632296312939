import { defineStore } from 'pinia'

import { useSessionStore } from './session'
import { useCartStore } from './cart'

export const useRewardsStore = defineStore('rewards', {
	state: () => ({
		rewards: [],
		paletteRewards: [],
		rewardCredit: 0,
		rewardDiscounts: 0,
		rewardCreditRemaining: 0,
		discount_items_count: 0,
		rewardDiscountsTotal: 0,
		rewardDiscountsUsed: 0,
		fiftyPercentItemsTotal: 0,
		fiftyPercentItemsUsed: 0,
		reward_exclude_50_percent: [],
		coupon: null,
	}),

	getters: {
		hasRewardCredit: (state) => state.rewardCredit > 0,
		hasRewardDiscounts: (state) => state.fiftyPercentItemsTotal - state.fiftyPercentItemsUsed > 0,
		isExcluded50PercentReward: (state) => (sku) => {
			return (
				state.rewards.find(
					(reward) => reward.reward_id === 'hostess_reward_fifty' && reward.non_qualifying_skus.includes(sku)
				) !== undefined
			)
		},
		remainingDiscountItemCount: (state) => {
			const remaining = state.rewardDiscounts - state.discount_items_count
			return Math.max(remaining, 0)
		},
		rewardCreditUsed: (state) => {
			for (const reward of state.rewards) {
				if (reward.reward_id === 'hostess_reward_credit') {
					const { total_store_credit, qualifying_items_subtotal } = reward
					if (qualifying_items_subtotal > total_store_credit) {
						return -total_store_credit
					}
					return -qualifying_items_subtotal
				}
			}
		},
		paletteCreditRewardLevel: (state) => {
			const levels = state?.paletteRewards?.levels
			if (levels) {
				const levelsTotal = levels.length
				for (let i = levelsTotal - 1; i >= 0; i--) {
					if (levels[i]?.qualifies) {
						return levels[i]
					}
				}
			}
			return null
		},
	},

	actions: {
		addRewardsFromCart({ rewards }) {
			this.setRewards(rewards)
		},
		async toggleDiscount({ discountCode, action }) {
			let response
			const isAdd = action === 'add'

			const session = useSessionStore()
			try {
				if (isAdd) {
					response = await axios.post(session.checkoutShopRoute('discounts.store'), { discountCode })
				} else {
					response = await axios.delete(session.checkoutShopRoute('discounts.remove'))
				}
			} catch (error) {
				throw error
			}

			const { data } = response
			const cart = useCartStore()
			cart.setCartItems(data.cart.items)
			cart.setSubtotal(data.cart.subtotal)
			cart.setOrderDiscountAmount(data.cart.rewards.orderDiscountAmount)
			this.rewards = data.rewards

			return response
		},
		setRewards(rewards) {
			this.rewards = rewards
			// compute remaining logic changes
			let foundCoupon = null
			for (const reward of rewards) {
				if (reward.reward_id === 'palette_credit' || reward.reward_id === 'double_palette_credit') {
					// Palette Credit
					this.paletteRewards = reward
				} else if (reward.reward_id === 'hostess_reward_credit') {
					// Hostess Credit
					const { total_store_credit, remaining_store_credit, levels } = reward
					this.rewardCredit = total_store_credit
					this.rewardCreditRemaining = remaining_store_credit
					const total = levels.length
					let used = 0
					for (const level of levels) {
						if (level.claimed) used++
					}
					this.rewardDiscountsTotal = total
					this.rewardDiscountsUsed = used
				} else if (reward.reward_id === 'hostess_reward_fifty') {
					// Hostess 50% Off
					const { levels } = reward
					const total = levels.length
					let used = 0
					for (const level of levels) {
						if (level.claimed) used++
					}
					this.fiftyPercentItemsTotal = total
					this.fiftyPercentItemsUsed = used
				} else if (reward.reward_id === 'coupon') {
					foundCoupon = reward
				}
			}
			this.coupon = foundCoupon
		},
	},
})
