<template>
	<div class="sm-container wrap">
		<h1 class="text-center">Branding and Transition</h1>
		<p class="typography-eyebrow">What is our new company name?</p>
		<p class="typography-callout">Seint</p>
		<p class="typography-callout">
			You will see “Seint Official” and “Seint Beauty” on our websites, social media, logowear, and other items, but
			“Seint” is our new company name and should be used by Artists.
		</p>

		<p class="typography-eyebrow">Why is Seint spelled with an ‘e’?</p>
		<p class="typography-callout">
			Seint is spelled in the traditional old French to represent the intrinsic purity and value of the word.
		</p>

		<p class="typography-callout">
			We will continue to add assets to the Dropbox as we make this transition together. Please submit any
			recommendations you have for Artist content to ASK.
		</p>

		<p class="typography-eyebrow">Why did we change our name?</p>
		<p class="typography-callout">
			Maskcara Beauty will always be a beloved part of our story. As the brand has grown, we have seen some issues with
			the name Maskcara; that the story of Cara’s blog gets lost, that we don’t actually sell mascara, that we don’t
			believe that makeup is a “mask,” and perhaps most importantly that this isn’t just Cara’s story. We wanted a name
			that Artists particularly could connect to and feel a sense of ownership of. Recognizing and enhancing the beauty
			that already exists in every face has always been our inspiration, so we are changing our name to better reflect
			our mission.
		</p>

		<p class="typography-eyebrow">What is the meaning behind ‘Seint’?</p>
		<p class="typography-callout">
			Our brand is about bringing beauty to the world and enhancing the beauty in every face. We believe there is a
			truth and a wholeness in beauty, and that concept kept bringing us back to beauty being divine and holy. We wanted
			a name that embraces the idea that beauty can stand on its own.
		</p>
		<p class="typography-callout">
			The name is inspired by our belief that seeking, experiencing, and cultivating beauty is a sacred work; the work
			of saints. Saints aren’t made or created; they are simply recognized for the good they bring to the world. In a
			similar way, we believe that we don’t make or create beauty; we recognize, nurture, and preserve it.
		</p>

		<p class="typography-eyebrow">What are the new colors and aesthetics of Seint?</p>
		<p class="typography-callout">Please see the brand guide for Artists where this information is outlined.</p>

		<p class="typography-eyebrow">How will customers be notified about the name change?</p>
		<p class="typography-callout">
			On November 6th, we will be hosting a special launch of the Seint brand on the main @maskcarabeauty Instagram
			account. During the party be prepared to see the account name switch over from @maskcarabeauty to @seintofficial.
			Beginning November 6th, we will be including a card in every order to notify customers of the change. We will also
			be talking about this change in depth via social media and email, and we are relying on each Artist to help
			educate and reassure their customers throughout the transition.
		</p>

		<p class="typography-eyebrow">When will everything be switched over completely?</p>
		<p class="typography-callout">
			We will be announcing the new name to all customers on November 6th, 2020. Our website, social media and a large
			majority of products will be Seint branded at this time. From November 6th to the Artist Birthday party on January
			15, 2021 there will be a transition period in which ALL products packaging will be switched over to Seint
			branding. The website will include the disclaimer “Product packaging may differ from product image” so that
			customers know that they might receive a product with different packaging than what is pictured during this
			transition period.
		</p>

		<p class="typography-eyebrow">Can I advertise the November 6th launch for my customers?</p>
		<p class="typography-callout">
			Yes! We will be providing specific images and messaging for Artists to share with customers to advertise the
			November 6th launch. We ask that Artists only use the marketing provided by the company so that we can present a
			cohesive first impression of our new brand look and feel.
		</p>

		<p class="typography-eyebrow">Where can I find Seint branded materials to use for my business?</p>
		<p class="typography-callout">
			Everything will be updated on Seint Gallery and in dropbox on November 6th. In the meantime, you will find the
			content in your back office.
		</p>
		<p class="typography-callout">
			We will continue to add assets to the Dropbox as we make this transfer together. Please submit any recommendations
			you have for Artist content to ASK.
		</p>

		<p class="typography-eyebrow">What are the new Artist rank names?</p>
		<p class="typography-callout">
			At this time, there are no changes to the compensation plan other than transitioning the names of our Artist ranks
			to better reflect our brand! Each rank represents a crucial step in your progression as an Artist. Because the
			Maskcara ranks are set up in “groups,” (HACers, Ambassadors, Influencers) we felt that Artists reaching a new rank
			within a group weren't able to be properly recognized for their accomplishment! Using the more classic approach of
			roman numerals, each rank is equal in emphasis and progressing to a new rank will always be a noteworthy
			achievement.
		</p>
		<div class="typography-callout old-new-grid">
			<p class="typography-eyebrow">Previous</p>
			<p class="typography-eyebrow">New</p>
			<p>HACER</p>
			<p>Artist I</p>
			<p>Elite HACER</p>
			<p>Artist II</p>
			<p>Top HACER</p>
			<p>Artist III</p>
			<p>Artist Ambassador</p>
			<p>Artist IV</p>
			<p>Elite Ambassador</p>
			<p>Artist V</p>
			<p>Top Ambassador</p>
			<p>Artist VI</p>
			<p>Influencer</p>
			<p>Artist VII</p>
			<p>Elite Influencer</p>
			<p>Artist VIII</p>
			<p>Top Influencer</p>
			<p>Artist IX</p>
			<p>Director</p>
			<p>Artist X</p>
		</div>

		<p class="typography-eyebrow">
			Will our Recognition Certificates and Jewelry that we have ALREADY earned and received be updated with the new
			rank names?
		</p>
		<p class="typography-callout">
			No. Since you qualified at specific ranks while the company was Maskcara, think of your certificates and jewelry
			as heritage pieces of your journey and history with Seint. The jewelry pieces will not be changing, only the Seint
			branding on the jewelry and certificates.
		</p>

		<p class="typography-eyebrow">When will we start to receive Seint branded Recognition Jewelry?</p>
		<p class="typography-callout">
			We will be sending out the Seint branded jewelry and certificates for November rank advancements, which means
			those pieces will ship mid-December. Depending on stock for each jewelry piece, you may receive a Seint branded
			item in November for October rank advancements.
		</p>

		<h2 class="text-center h1">Website and Social Media</h2>

		<p class="typography-eyebrow">What website do we use?</p>
		<p class="typography-callout">Visitors will automatically be redirected to our new website domains.</p>
		<div class="typography-callout old-new-grid">
			<p class="typography-eyebrow">Previous</p>
			<p class="typography-eyebrow">New</p>
			<p>maskcarabeauty.com</p>
			<p>seintofficial.com</p>
			<p>maskcarahq.com</p>
			<p>seintgallery.com</p>
		</div>

		<p class="typography-eyebrow">
			Will my Maskcara link, customers, and parties be transferred over to the Seint website?
		</p>
		<p class="typography-callout">
			Yes, your replicated site will be updated. If your customers go to your old URL, the site will automatically
			redirect to your new site name. Though we still recommend updating them after the release.
		</p>

		<p class="typography-eyebrow">What are the social media accounts?</p>
		<p class="typography-callout">
			Visitors will automatically be redirected to our new social media accounts. This change may take 24-48 hours to
			transition over.
		</p>
		<p class="typography-callout">
			Instagram: @seintofficial (for customers), @seintartists @seintparty @seintevents
			<br />
			Facebook: Seint Official
		</p>

		<p class="typography-eyebrow">
			When do I need to switch over all of my Maskcara branded material and social media handles?
		</p>
		<p class="typography-callout">
			We suggest switching over all things Maskcara branded, including social media accounts, as soon as possible after
			the announcement to customers to maintain a consistent look and united brand image. We know our Artists have put a
			lot of time and effort into their Maskcara materials and resources, so we know it may take some time for you to
			work on rebranding your content. We only ask that you update the content in a timely and thoughtful manner.
		</p>
		<p class="typography-callout">
			Please note - Artists may <span class="text-bold">NOT</span> use a social media handle or artist links that
			includes the word Seint. We want Artists to be able to connect with their followers in a way that is genuine and
			personalized to them. Being an Independent Seint Artist is just one of the things that makes you, you. Your social
			media handle and artist link should reflect your own unique brand!
		</p>

		<p class="typography-eyebrow">What can I anticipate to be updated on the website?</p>
		<p class="typography-callout">
			You can expect the functionality of the website to remain the same; navigating the pages won’t change. You can
			anticipate updated aesthetics, as well as messaging for the rebranding.
		</p>

		<p class="typography-eyebrow">Will the Artist Policies and Terms be changing?</p>
		<p class="typography-callout">
			Our Artist Policies and Terms will have some new updates! Evolving regulations require some changes from time to
			time so that we can be sure our company is in compliance with current laws. We want to be vigilant about doing all
			we can to protect our Artists in their businesses. Please carefully
			<a
				href="https://s3.amazonaws.com/maskcarabeauty.com/backoffice/revised-p-and-p.pdf"
				target="_blank"
				rel="noopener noreferrer"
				aria-label="Read the updated agreement"
				>read the updated Agreement</a
			>. These policies and terms will be effective beginning Monday, November 30th.
		</p>

		<h2 class="text-center h1">Products</h2>

		<p class="typography-eyebrow">How will the look of products and packaging be changing?</p>
		<p class="typography-callout">
			Brushes, perfectors, palettes, and the skincare line are all getting an updated look, but the ingredients and
			functionality of each product will stay exactly the same. You will notice a very subtle change with the Seint
			rebranded tins. Please see the question above,
			<span class="text-italic">“When will everything be switched over completely?”</span>, to manage expectations on
			when Artists and customers will begin to see these newly branded products in orders.
		</p>

		<p class="typography-eyebrow">Will the names of our colors and products be changing?</p>
		<p class="typography-callout">
			All product packaging will be updated to reflect the name change. Some products will also have an updated name,
			but the actual product will remain the same. The list of products with new names is below:
		</p>

		<div class="typography-callout old-new-grid">
			<p class="typography-eyebrow">Previous</p>
			<p class="typography-eyebrow">New</p>
			<p>30 Second HAC Brush</p>
			<p>IIID Brush</p>
			<p>The Detail HAC Brush</p>
			<p>Detail Brush</p>
			<p>Power Powder Brush</p>
			<p>Powder Brush</p>
			<p>B Squared Bronzer and Blush Brush</p>
			<p>Blush/Bronzer Brush</p>
			<p>I Shadow Everything Brush</p>
			<p>Eyeshadow Brush</p>
			<p>The Multitasker Brush</p>
			<p>Multitasker Brush</p>
			<p>Best Blend Forever</p>
			<p>Blend Brush</p>
			<p>Blend and Tap</p>
			<p>Smudge Brush</p>
			<p>Buffy Brush</p>
			<p>Buff Brush</p>
			<p>Hotline Brush</p>
			<p>Line Brush</p>
			<p>Stay Setting Spray</p>
			<p>Setting Spray</p>
			<p>Houdini Brush Cleanser</p>
			<p>Brush Cleanser</p>
			<p>Milk Lip Conditioner - Cherry</p>
			<p>Lip Conditioner - Cherry</p>
			<p>Milk Lip Conditioner - Peppermint</p>
			<p>Lip Conditioner - Peppermint</p>
			<p>Milk Microfiber Cloths - Classic</p>
			<p>Microfiber Cloths - White</p>
			<p>Milk Microfiber Cloths - Tidal</p>
			<p>Microfiber Cloths - Gray</p>
			<p>Milk Refresh Wipes</p>
			<p>Seint Refresh Wipes</p>
			<p>Milk Masque</p>
			<p>Seint Masque</p>
			<p>Milk Crème</p>
			<p>Creme</p>
			<p>Milk Toner</p>
			<p>Toner</p>
			<p>Milk Cleanser</p>
			<p>Cleanser</p>
			<p>Tres Leches Collection</p>
			<p>Skincare System</p>
			<p>Maskcara No. 1</p>
			<p>Seint 1</p>
			<p>Maskcara No. 2</p>
			<p>Seint 2</p>
			<p>Maskcara No. 3</p>
			<p>Seint 3</p>
			<p>Maskcara No. 4</p>
			<p>Seint 4</p>
		</div>

		<p class="typography-eyebrow">Will any current products be discontinued?</p>
		<p class="typography-callout">
			A few products will be discontinued to consolidate our lines and keep them well stocked. These include:
		</p>
		<ul class="typography-callout">
			<li>Stay Setting Spray with SPF</li>
			<li>Milk Creme - Tidal</li>
			<li>Milk Cleanser - Tidal</li>
			<li>Milk Toner - Tidal</li>
		</ul>
		<p class="typography-callout">
			Any products that are seasonal or limited edition will be discontinued as planned once the current stock is sold
			out.
		</p>

		<p class="typography-eyebrow">Will current Maskcara branded products be discounted?</p>
		<p class="typography-callout">
			Black Friday will feature some specials that will offer discounted pricing on select remaining Maskcara-branded
			inventory. More information about these specials will be coming your way after the November 6th announcement!
		</p>

		<p class="typography-eyebrow">Will we still have IIID foundation as we know it with tins and palettes?</p>
		<p class="typography-callout">Yes!</p>

		<p class="typography-eyebrow">
			Will there be a Seint branded product bundle available to purchase if we want to buy updated Seint branded
			products?
		</p>
		<p class="typography-callout">
			Yes, we will have a Seint branded IIID bundle available for the Artist birthday party! This bundle will include
			products that are currently Maskcara branded, like tools and palettes so you can have updated products on hand to
			show customers. Because our makeup tins don’t have visible branding once the lids are removed, these will not be
			included. The functionality of the products will be the exact same so having Seint branded products is NOT a
			requirement, but we wanted to make this available to those interested. We will be sharing more details on this
			special bundle soon, so stay tuned!
		</p>

		<p class="typography-eyebrow">When will Artist IIID Signup Kits switch over to Seint?</p>
		<p class="typography-callout">
			New Artist signups will receive Maskcara branded Pro and Basic IIID Artist Kits until December depending on the
			number Artist signups and inventory. After those kits are gone there will be a transition period where Artist IIID
			Kits may include a mix of Maskcara Beauty and Seint branded product packaging. Beginning on January 15th, 2021,
			all Artist IIID Kits sent out will be entirely Seint branded. The Basic and Pro IIID Foundation Kits will remain
			the same price. Please note, the change in branding on the Artist Kits is minimal. If a new Artist decides they
			want a Seint branded palette or brushes they will have the opportunity to purchase those with hostess rewards
			later on.
		</p>
	</div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.old-new-grid {
	display: grid;
	grid-template-columns: repeat(2, minmax(min-content, max-content));
	grid-gap: 8px 16px;

	> p {
		margin: 0;
	}
}
</style>
