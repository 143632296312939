<template>
	<div @click="handleDismissModal" class="swal2-container swal2-center swal2-backdrop-show" style="overflow-y: auto">
		<div
			@click.stop
			aria-labelledby="swal2-title"
			aria-describedby="swal2-content"
			class="swal2-popup swal2-modal swal2-show"
			tabindex="-1"
			role="dialog"
			aria-live="assertive"
			aria-modal="true"
			style="display: flex"
		>
			<div class="swal2-header">
				<ul class="swal2-progress-steps" style="display: none"></ul>
				<div class="swal2-icon swal2-error" style="display: none"></div>
				<div class="swal2-icon swal2-question" style="display: none"></div>
				<div class="swal2-icon swal2-warning" style="display: none"></div>
				<div class="swal2-icon swal2-info" style="display: none"></div>
				<div class="swal2-icon swal2-success" style="display: none"></div>
				<img class="swal2-image" alt="Swal Image" style="display: none" />
				<h2 class="swal2-title" id="swal2-title" style="display: flex">Select Your Skin Tone</h2>
				<button
					type="button"
					class="swal2-close"
					style="display: flex"
					aria-label="Close This Dialog"
					@click="handleDismissModal"
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
						<path
							d="M6.05 17.95l11.9-11.9m-11.9 0l11.9 11.9"
							fill="none"
							stroke="currentcolor"
							stroke-width="1.5"
						></path>
					</svg>
				</button>
			</div>
			<div class="swal2-content swal2-content--left">
				<div id="swal2-content" class="swal2-html-container" style="display: block">
					<ul class="grid grid-cols-1 md:grid-cols-2 gap-3 list-none p-0 mt-3">
						<li
							v-for="(level, index) in collections"
							:key="level"
							class="flex flex-wrap justify-between gap-2 !pb-4 !border-b !border-black/40"
						>
							<span class="text-[11px]/[11px] uppercase tracking-wider py-2 pr-2 !border !border-transparent">{{
								level
							}}</span>
							<span
								class="text-xs/[12px] uppercase tracking-wider font-medium p-2 cursor-pointer !border !border-black"
								v-if="validateItemAtLevel(level)"
								@click="handleSelectLevel(level)"
							>
								Select
							</span>
							<img
								class="w-full object-cover object-center shrink-0"
								:src="`${$assetBucketBaseUrl}/media/images/skin-tones/v2/${level
									.replace(/\s+/g, '-')
									.toLowerCase()}--group.jpg`"
								:alt="`Models with skin tone level ${index + 1}`"
							/>
						</li>
					</ul>
				</div>
				<input class="swal2-input" style="display: none" />
				<input type="file" class="swal2-file" style="display: none" />
				<div class="swal2-range" style="display: none">
					<input type="range" />
					<output></output>
				</div>
				<select class="swal2-select" style="display: none"></select>
				<div class="swal2-radio" style="display: none"></div>
				<label for="swal2-checkbox" class="swal2-checkbox" style="display: none">
					<input type="checkbox" /><span class="swal2-label"></span>
				</label>
				<textarea class="swal2-textarea" style="display: none"></textarea>
				<div class="swal2-validation-message" id="swal2-validation-message"></div>
			</div>
			<div class="swal2-actions" style="display: none">
				<button type="button" class="swal2-confirm" style="display: none" aria-label="Ok">OK</button>
				<button type="button" class="swal2-cancel" style="display: none" aria-label="Cancel">Cancel</button>
			</div>
			<div class="swal2-footer" style="display: none"></div>
			<div class="swal2-timer-progress-bar-container">
				<div class="swal2-timer-progress-bar" style="display: none"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
		},
	},
	emits: ['select-item-from-modal'],
	data() {
		return {
			collections: [
				'Level I',
				'Level II',
				'Level III',
				'Level IV',
				'Level V',
				'Level VI',
				'Level VII',
				'Level VIII',
				'Level IX',
				'Level X',
				'Level XI',
				'Level XII',
			],
		}
	},
	methods: {
		validateItemAtLevel(level) {
			return this.items.find((item) => item.shortTitle === level)
		},
		handleSelectLevel(level) {
			this.$emit('select-item-from-modal', this.validateItemAtLevel(level) || null)
		},
		handleDismissModal() {
			this.$emit('select-item-from-modal', null)
		},
	},
}
</script>

<style lang="scss" scoped>
.swal2-validation-message {
	display: none;
}
.swal2-container {
	overscroll-behavior-y: contain;
}
.swal2-popup {
	width: 100%;
	max-width: 774px;
	padding: 32px 24px;
}
</style>
