import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

import cartClient from '@/js/api/cart.js'
import { useRewardsStore } from '@stores'

const ITEM_LIMITS = [
	1788, // Lip + Cheek - Pink Slip
	1787, // Lip + Cheek - Disco Inferno
	1790, // Lip + Cheek - Duckie
	1789, // Lip + Cheek - Lavender Skies
	1786, // Bronzer - Xanadu
	1791, // Solid Perfume - Stardust
	1792, // Stardust Rollerball
	1795, // Stardust Fragrance Collection
	1644, // Birthday Lip Oil
	1652, // Diamonds are Forever
	1782, // Eyeshadow - Stevie
	1783, // Eyeshadow - Bowie
	1784, // Eyeshadow - Cher
	1796, // Crystal Palace Square Collection
	1772, // IIID Palette 4 - Crystal Palace
	1773, // IIID Palette 8 - Crystal Palace
	1774, // IIID Palette 12 - Crystal Palace
	1775, // IIID Palette 18 - Crystal Palace
	1776, // IIID Palette 27 - Crystal Palace
	1777, // IIID Palette 4 - Mirrorball
	1778, // IIID Palette 8 - Mirrorball
	1779, // IIID Palette 12 - Mirrorball
	1780, // IIID Palette 18 - Mirrorball
	1781, // IIID Palette 27 - Mirrorball
	1794, // Mercury Compact Mirror
	1785, // 1/2 Brush Cleaning Tile
]

export const useCartStore = defineStore('cart', {
	state: () => ({
		cartItems: [],
		subtotal: 0,
		orderDiscountAmount: 0,
		changingQuantity: {},
		rewardModalOpen: false,
		rewardModalDetails: {},
	}),

	getters: {
		isCartEmpty: (state) => state.cartItems.length === 0,
		getKit: (state) => state.cartItems.find((item) => item.is_kit),
		itemCount: (state) => state.cartItems.length,
		quantityTotal: (state) => {
			return state.cartItems.reduce((acc, item) => acc + item.quantity, 0)
		},
		ephemoralCount: (state) => (lineItemId) => {
			return state.cartItems
				.filter((item) => lineItemId === item.line_item_id)
				.reduce((acc, curr) => acc + curr.quantity, 0)
		},
		hasItemForPaletteCreditInCart: (state) => {
			const lookup = state.cartItems.find((item) => item.discount_id?.includes('palette_credit'))
			return lookup !== undefined
		},
		cartItemIds: (state) => state.cartItems.map(({ details }) => details.itemId),
	},

	actions: {
		openRewardModal() {
			this.rewardModalOpen = true
		},
		closeRewardModal() {
			this.rewardModalOpen = false
		},
		updateModalAttributes(attributes) {
			this.rewardModalDetails = attributes
		},
		clearCart() {
			this.cartItems = []
		},
		setCartItems(cartItems) {
			this.cartItems = cartItems
		},
		setSubtotal(subtotal) {
			this.subtotal = subtotal
		},
		setOrderDiscountAmount(orderDiscountAmount) {
			this.orderDiscountAmount = orderDiscountAmount
		},
		changeQuantity({ itemIndex, quantity }) {
			const cartItems = this.cartItems
			cartItems[itemIndex].quantity = quantity
			this.cartItems = cartItems
		},
		isItemInCart({ itemId }) {
			const today = dayjs.tz(dayjs(), 'America/Denver')
			const saleStart = dayjs.tz('2024-07-22', 'America/Denver').hour(0) // 12AM on 22nd
			const applicable = today.isBefore(saleStart)
			return applicable && ITEM_LIMITS.includes(itemId) && this.cartItemIds.includes(itemId)
		},
		async addToCart({ itemId, quantity, isKit, discountType }) {
			if (this.isItemInCart({ itemId })) {
				const { rewards } = useRewardsStore()
				return {
					data: {
						status: 'prevent',
						message: 'Not Allowed',
						rewards,
						items: this.cartItems,
						subtotal: this.subtotal,
					},
				}
			}
			try {
				const { data } = await cartClient.addItem(itemId, quantity, isKit, discountType)
				const {
					items,
					subtotal,
					rewards: { orderDiscountAmount },
				} = data
				this.cartItems = items
				this.subtotal = subtotal
				this.orderDiscountAmount = orderDiscountAmount
				return { data }
			} catch (error) {
				console.error('addToCart() error: ', error)
				return { data: {} }
			}
		},
		async updateItemQuantity({ lineItemId, quantity, oldQuantity }) {
			let itemIndex
			try {
				if (quantity === oldQuantity) {
					return
				}
				itemIndex = this.cartItems.findIndex((item) => item.line_item_id === lineItemId)
				if (itemIndex === -1) {
					return
				}
				const itemId = this.cartItems[itemIndex].details.itemId
				if (this.isItemInCart({ itemId })) {
					const { rewards } = useRewardsStore()
					return {
						data: {
							status: 'prevent',
							message: 'Not Allowed',
							rewards,
							items: this.cartItems,
							subtotal: this.subtotal,
						},
					}
				}
				let newQuantity = quantity
				const ephemoralCheck = this.ephemoralCount(lineItemId)
				if (ephemoralCheck !== oldQuantity) {
					const direction = quantity - oldQuantity > 0 ? 1 : -1
					newQuantity = ephemoralCheck + direction
				}
				this.changingQuantity[lineItemId] = lineItemId
				this.changeQuantity({ itemIndex, quantity: newQuantity })

				const { data } = await cartClient.updateItem(lineItemId, newQuantity)
				const {
					items,
					subtotal,
					rewards: { orderDiscountAmount },
				} = data
				this.cartItems = items
				this.subtotal = subtotal
				this.orderDiscountAmount = orderDiscountAmount
				delete this.changingQuantity[lineItemId]
				return { data }
			} catch (error) {
				console.error('updateItemQuantity() error: ', error)
				this.changeQuantity({ itemIndex, quantity: oldQuantity })
				delete this.changingQuantity[lineItemId]
			}
		},
		async removeFromCart({ lineItemId }) {
			try {
				this.changingQuantity[lineItemId] = lineItemId
				const { data } = await cartClient.removeItem(lineItemId)
				const {
					items,
					subtotal,
					rewards: { orderDiscountAmount },
				} = data
				this.cartItems = items
				this.subtotal = subtotal
				this.orderDiscountAmount = orderDiscountAmount
				delete this.changingQuantity[lineItemId]
				return { data }
			} catch (error) {
				throw new Error(error)
			}
		},
	},
})
