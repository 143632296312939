<template>
	<form @submit.prevent class="product--add-item">
		<div class="product--button">
			<button v-if="isFreeGift" disabled class="btn-outline-black" aria-label="Free Gift">
				{{ isFreeGift.alt_button_text }}
			</button>
			<add-to-cart-btn
				v-else-if="!shouldShowDropdownButton && !cannotPurchase"
				:pending-release="!item.isReleasedForCustomer"
				:pending-release-text="formattedReleaseDate"
				:cannot-purchase="cannotPurchase"
				:out-of-stock="item.outOfStock"
				:not-available="!item.available"
				:quantity="quantity"
				:item-id="item.itemId"
				btn-type="submit"
				@loading="onLoading"
				@added="onAdded"
			/>
			<template v-else>
				<button
					v-if="cannotPurchase"
					disabled
					class="btn btn-primary"
					:aria-label="item.outOfStock ? 'Out of Stock' : !item.available ? 'Not Available' : 'Unavailable'"
				>
					{{ item.outOfStock ? 'Out of Stock' : !item.available ? 'Not Available' : 'Unavailable' }}
				</button>
				<div v-else class="contain-btn-with-options" :class="[showBtnOptions && 'show-options', loading && 'loading']">
					<button
						class="btn btn-primary btn-with-options"
						@click="handleDropdownBtn"
						:disabled="loading"
						aria-label="Add to Bag"
					>
						<svg-vue icon="bag-v2" />
						Add to Bag…
					</button>
					<div class="btn-options">
						<span v-show="loading" class="loader"></span>
						<add-to-cart-btn
							v-if="isHostContext"
							btn-class="btn-option"
							:use-discount="true"
							discount-type="hostess_reward_fifty"
							:item-id="item.itemId"
							:cannot-purchase="cannotPurchase || isExcluded50PercentReward(item.itemCode)"
							:out-of-stock="item.outOfStock"
							:not-available="!item.available"
							:quantity="1"
							@loading="onLoading"
							@added="onAdded"
						>
							<template v-if="!isExcluded50PercentReward(item.itemCode)" v-slot:text>
								<span> Use {{ quantity > 1 ? ' 1 ' : ' ' }}50% Off ({{ remaining50PercentOffCount }} left) </span>
							</template>
							<template v-else v-slot:text>
								<span> Cannot Use 50% Off </span>
							</template>
						</add-to-cart-btn>
						<add-to-cart-btn
							btn-class="btn-option"
							:cannot-purchase="cannotPurchase"
							:out-of-stock="item.outOfStock"
							:not-available="!item.available"
							:quantity="quantity"
							:item-id="item.itemId"
							btn-type="submit"
							@loading="onLoading"
							@added="onAdded"
						>
							<template v-slot:text> Add{{ quantity > 1 ? ` ${quantity} ` : ' ' }}at Full Price </template>
						</add-to-cart-btn>
					</div>
				</div>
			</template>
			<p v-if="saleApplicable" class="sale-message">
				This item is final sale and is not eligible for return or exchange.
				<template v-if="salePaletteApplicable"> Discounted palettes cannot be purchased with Palette Credit.</template>
			</p>
		</div>
	</form>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import dayjs from 'dayjs'

import { useSessionStore, useRewardsStore, useCartStore } from '@stores'

export default {
	props: {
		item: {
			type: Object,
			required: true,
		},
		saleInformation: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			quantity: 1,
			loading: false,
			showBtnOptions: false,
		}
	},
	computed: {
		...mapState(useRewardsStore, [
			'fiftyPercentItemsTotal',
			'fiftyPercentItemsUsed',
			'rewardDiscountsTotal',
			'rewardDiscountsUsed',
			'hasRewardDiscounts',
			'isExcluded50PercentReward',
		]),
		...mapState(useSessionStore, ['isHostContext', 'isEnrollContext']),
		saleApplicable() {
			return this.saleInformation?.is_promotion
		},
		salePaletteApplicable() {
			const skus = [
				'120150', // Demi Palette 6 - Mood
				'120151', // Demi Palette 12 - Mood
				'120152', // Demi Palette 18 - Mood
				'120153', // Demi Palette 20 - Mood
				'120154', // Demi Palette 49 - Mood
				'120177', // Demi Onyx Compact 12
				'120178', // Demi Onyx Compact 18
				'120179', // Demi Onyx Compact 20
				'120180', // Demi Onyx Compact 49
				'120252', // IIID Palette 18 - Vanilla
				'120253', // IIID Palette 27 - Vanilla
				'120273', // IIID Palette 27 - Chateau
				'120280', // IIID Palette 27 - Tinsel
				'120313', // IIID Palette 8 - Amalfi
				'120316', // IIID Palette 27 - Amalfi
				'120318', // IIID Palette 8 - Valencia
				'120321', // IIID Palette 27 - Valencia
				'120641', // IIID Palette 18 - 007
				'120132', // IIID Palette 27 - Silver Metallic
				'120636', // Pro Palette 40 - Roses Red
				'190302', // Jinx Drawstring Bag
			]
			return this.saleInformation.qualifying_skus.some((sku) => skus.includes(sku))
		},
		formattedReleaseDate() {
			if (!this.item.isReleasedForCustomer) {
				const [month, dayParts] = dayjs
					.utc(this.item.releaseDateForCustomer)
					.tz(dayjs.tz.guess())
					.format('MMMM Do')
					.split(' ')
				const day = dayParts.slice(0, -2)
				const ordinal = dayParts.slice(-2)
				return `Available ${month} ${day}<sup>${ordinal}</sup>`
			}
		},
		isFreeGift() {
			if (this.saleInformation && !this.saleInformation.can_purchase) {
				return this.saleInformation
			}
			return false
		},
		remaining50PercentOffCount() {
			const remaining = this.fiftyPercentItemsTotal - this.fiftyPercentItemsUsed
			if (remaining < 0) {
				return 0
			}
			return remaining
		},
		remainingDiscountItemCount() {
			const remaining = this.rewardDiscountsTotal - this.rewardDiscountsUsed
			if (remaining < 0) {
				return 0
			}
			return remaining
		},
		maxQuantity() {
			return this.item.maxAllowedOnOrder < 0 ? 0 : this.item.maxAllowedOnOrder < 20 ? this.item.maxAllowedOnOrder : 20
		},
		maxQuantityOrStock() {
			return this.maxQuantity < this.stockAvailable ? this.maxQuantity : this.stockAvailable
		},
		cannotPurchase() {
			return !this.item.available || this.item.outOfStock || this.isEnrollContext
		},
		shouldShowDropdownButton() {
			return this.hasRewardDiscounts && this.isHostContext && this.remaining50PercentOffCount > 0
		},
	},
	methods: {
		...mapActions(useCartStore, ['addToCart']),
		onLoading(value) {
			this.loading = !!value
		},
		onAdded(value) {
			if (value) {
				this.quantity = 1
				this.showBtnOptions = false
			}
		},
		handleDropdownBtn() {
			this.showBtnOptions = !this.showBtnOptions
		},
	},
}
</script>
<style lang="scss" scoped>
@import '../../../sass/unishop/helpers/_mixins.scss';
@import '../../../sass/unishop/helpers/_variables.scss';

.contain-btn-with-options {
	position: relative;

	.btn-primary {
		position: relative;
		z-index: 4;
	}

	.btn-options {
		pointer-events: none;
		opacity: 0;
		transform: translateY(24px);
		transition: transform 100ms ease-in, opacity 100ms ease-in;

		position: absolute;
		z-index: 3;
		width: calc(100% - 16px);
		left: 8px; // keep centered after narrowing width
		bottom: calc(100% + 8px);
		border: 0.5px solid $border-color;
		box-shadow: 0 3px 8px 0 rgba(black, 0.3);
		background-color: white;

		@include bp(lg-up) {
			transform: translateY(-24px);

			bottom: initial;
			top: calc(100% + 8px);
		}
	}

	.btn-option {
		padding: 13px 16px;
		background-color: white;
		transition: background-color 50ms ease-in;

		&:disabled {
			opacity: 0.5;

			&:hover {
				cursor: not-allowed;
			}
		}

		&:not(:disabled):hover {
			background: rgba(black, 0.025);
		}

		& + .btn-option {
			border-top: 0.5px solid $border-color;
		}
	}

	&.show-options {
		.btn-options {
			pointer-events: initial;
			opacity: 1;
			transform: translateY(0);
		}
	}

	&.loading {
		pointer-events: none;

		.loader {
			display: block;
		}
	}
}
.sale-message {
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #9e3501;
	margin-top: 20px;
}
</style>
