import { createApp, defineAsyncComponent } from 'vue'
import { createPinia } from 'pinia'
import VueDragscroll from 'vue-dragscroll'
import { createWebHistory, createRouter } from 'vue-router'
import VScrollLock from 'v-scroll-lock'
import VueTheMask from 'vue-the-mask'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import toast from 'izitoast'
import VueSignaturePad from 'vue-signature-pad'
import 'babel-polyfill'
// misc plugins
import './plugins/jquery.min.js'
import './bootstrap.js'
import './plugins/bootstrap.min.js'
import './helpers.js'
import './modal-helpers.js'
import './seint.js'

const CheckboxInput = defineAsyncComponent(() => import('@components/_new/CheckboxInput.vue'))
const HeadlessAccordion = defineAsyncComponent(() => import('@components/_new/HeadlessAccordion.vue'))
const HeadlessModal = defineAsyncComponent(() => import('@components/_new/HeadlessModal.vue'))
const RadioInput = defineAsyncComponent(() => import('@components/_new/RadioInput.vue'))
const AnchorScroll = defineAsyncComponent(() => import('@components/AnchorScroll.vue'))
const Braintree = defineAsyncComponent(() => import('@components/Braintree.vue'))
const Carousel = defineAsyncComponent(() => import('@components/Carousel.vue'))
const ContactForm = defineAsyncComponent(() => import('@components/ContactForm.vue'))
const EmailSubscribe = defineAsyncComponent(() => import('@components/EmailSubscribe.vue'))
const EmailUnsubscribeForm = defineAsyncComponent(() => import('@components/EmailUnsubscribe.vue'))
const ErrorMessages = defineAsyncComponent(() => import('@components/ErrorMessages.vue'))
const EventRegistrationEditForm = defineAsyncComponent(() => import('@components/EventRegistrationEditForm.vue'))
const EventRegistrationForm = defineAsyncComponent(() => import('@components/EventRegistrationForm.vue'))
const FormAddress = defineAsyncComponent(() => import('@components/FormAddress.vue'))
const GenericModal = defineAsyncComponent(() => import('@components/GenericModal.vue'))
const HeroBanner = defineAsyncComponent(() => import('@components/HeroBanner.vue'))
const HorizontalCtaItems = defineAsyncComponent(() => import('@components/HorizontalCtaItems.vue'))
const HumanizeRelativeDate = defineAsyncComponent(() => import('@components/HumanizeRelativeDate.vue'))
const ImageUpload = defineAsyncComponent(() => import('@components/ImageUpload.vue'))
const SvgVue = defineAsyncComponent(() => import('@components/SvgVue.vue'))
const LabelTag = defineAsyncComponent(() => import('@components/LabelTag.vue'))
const LazyPaginator = defineAsyncComponent(() => import('@components/LazyPaginator.vue'))
const Loader = defineAsyncComponent(() => import('@components/Loader.vue'))
const MarketChoice = defineAsyncComponent(() => import('@components/MarketChoice.vue'))
import IntroducingSeintLayout from '@components/IntroducingSeintLayout/index.vue'
import IntroducingSeintFAQ from '@components/IntroducingSeintLayout/IntroducingSeintFAQ.vue'
import IntroducingSeintIntro from '@components/IntroducingSeintLayout/IntroducingSeintIntro.vue'
const IntroducingSeintMarketing = defineAsyncComponent(() =>
	import('@components/IntroducingSeintLayout/IntroducingSeintMarketing.vue')
)
const IntroducingSeintRecognition = defineAsyncComponent(() =>
	import('@components/IntroducingSeintLayout/IntroducingSeintRecognition.vue')
)
const IntroducingSeintTimeline = defineAsyncComponent(() =>
	import('@components/IntroducingSeintLayout/IntroducingSeintTimeline.vue')
)
const ProductList = defineAsyncComponent(() => import('@components/ProductLists/ProductList.vue'))
const ProductListCard = defineAsyncComponent(() => import('@components/ProductLists/ProductListCard.vue'))
const ProductListForm = defineAsyncComponent(() => import('@components/ProductLists/ProductListForm.vue'))
const ProductListHeader = defineAsyncComponent(() => import('@components/ProductLists/ProductListHeader.vue'))
const ProductListItems = defineAsyncComponent(() => import('@components/ProductLists/ProductListItems.vue'))
const ProductListLoginForm = defineAsyncComponent(() => import('@components/ProductLists/ProductListLoginForm.vue'))
const ProductListChoice = defineAsyncComponent(() => import('@components/ProductLists/ProductListChoice.vue'))
const ProductListSearch = defineAsyncComponent(() => import('@components/ProductLists/ProductListSearch.vue'))
const ProductListTempBulkAdd = defineAsyncComponent(() => import('@components/ProductLists/ProductListTempBulkAdd.vue')) // TODO: can delete once ready
const MeiliArtistSearch = defineAsyncComponent(() => import('@components/Meili/Artist/Search.vue'))
const MeiliProductSearch = defineAsyncComponent(() => import('@components/Meili/Product/Search.vue'))
const MeiliProductListSearch = defineAsyncComponent(() => import('@components/Meili/ProductList/Search.vue'))
const MarketCurrency = defineAsyncComponent(() => import('@components/MarketCurrency.vue'))
const MediaCarousel = defineAsyncComponent(() => import('@components/MediaCarousel.vue'))
const OrderConfirmation = defineAsyncComponent(() => import('@components/OrderConfirmation.vue'))
const PayLaterMessage = defineAsyncComponent(() => import('@components/PayLaterMessage.vue'))
import ProductLayout from '@components/ProductLayout/index.vue'
const AddPaymentMethodForm = defineAsyncComponent(() => import('@components/Profile/AddPaymentMethodForm.vue'))
const EditAddressForm = defineAsyncComponent(() => import('@components/Profile/EditAddressForm.vue'))
const EditDobForm = defineAsyncComponent(() => import('@components/Profile/EditDobForm.vue'))
const EditEmailForm = defineAsyncComponent(() => import('@components/Profile/EditEmailForm.vue'))
const EditLoginForm = defineAsyncComponent(() => import('@components/Profile/EditLoginForm.vue'))
const EditNameForm = defineAsyncComponent(() => import('@components/Profile/EditNameForm.vue'))
const EditPaymentMethodForm = defineAsyncComponent(() => import('@components/Profile/EditPaymentMethodForm.vue'))
const EditPhoneForm = defineAsyncComponent(() => import('@components/Profile/EditPhoneForm.vue'))
const EditTaxNameForm = defineAsyncComponent(() => import('@components/Profile/EditTaxNameForm.vue'))
const VerifyAddressModal = defineAsyncComponent(() => import('@components/Profile/VerifyAddressModal.vue'))
const AddToBag = defineAsyncComponent(() => import('@components/Returns/AddToBag.vue'))
const ContinueBtn = defineAsyncComponent(() => import('@components/Returns/ContinueBtn.vue'))
const ItemOptions = defineAsyncComponent(() => import('@components/Returns/ItemOptions.vue'))
const ItemSelection = defineAsyncComponent(() => import('@components/Returns/ItemSelection.vue'))
const ReturnMessage = defineAsyncComponent(() => import('@components/Returns/ReturnMessage.vue'))
import PriceInfo from '@components/PriceInfo.vue'
const RewardModal = defineAsyncComponent(() => import('@components/RewardModal.vue'))
const RewardsProgram = defineAsyncComponent(() => import('@components/RewardsProgram.vue'))
const RewardsProgramTable = defineAsyncComponent(() => import('@components/RewardsProgramTable.vue'))
const PrintMe = defineAsyncComponent(() => import('@components/PrintMe.vue'))
const SpecialTaxes = defineAsyncComponent(() => import('@components/SpecialTaxes.vue'))
const SubscribeEmail = defineAsyncComponent(() => import('@components/SubscribeEmail.vue'))
const SharedCartModal = defineAsyncComponent(() => import('@components/SharedCart/Modal.vue'))
const SuperKey = defineAsyncComponent(() => import('@components/SuperKey.vue'))
const SwitchInput = defineAsyncComponent(() => import('@components/SwitchInput.vue'))
const ToggleValue = defineAsyncComponent(() => import('@components/ToggleValue.vue'))
const TrainingVideos = defineAsyncComponent(() => import('@components/TrainingVideos.vue'))
const AddToCartBtn = defineAsyncComponent(() => import('@views/item/AddToCartBtn.vue'))
const ArtistInfoCard = defineAsyncComponent(() => import('@components/ArtistInfoCard.vue'))
const CartList = defineAsyncComponent(() => import('@views/cart/CartList.vue'))
const Rewards = defineAsyncComponent(() => import('@views/cart/Rewards.vue'))
const RewardsHostess = defineAsyncComponent(() => import('@views/cart/RewardsHostess.vue'))
const RewardsWrapper = defineAsyncComponent(() => import('@views/cart/RewardsWrapper.vue'))
import CartItemForm from '@views/item/CartItemForm.vue'
const CartItems = defineAsyncComponent(() => import('@views/checkout/CartItems.vue'))
const CheckoutForm = defineAsyncComponent(() => import('@views/checkout/CheckoutForm.vue'))
const DownlineViewer = defineAsyncComponent(() => import('@components/DownlineViewer/index.vue'))
const EnrollmentAccountForm = defineAsyncComponent(() => import('@components/Enrollment/AccountForm.vue'))
const EnrollmentCheckout = defineAsyncComponent(() => import('@components/Enrollment/Checkout.vue'))
const EnrollmentKitSelection = defineAsyncComponent(() => import('@components/Enrollment/KitSelection.vue'))
const ItemQuantity = defineAsyncComponent(() => import('@views/item/ItemQuantity.vue'))
const ManagePartyForm = defineAsyncComponent(() => import('@views/office/ManagePartyForm.vue'))
const PaletteBuilder = defineAsyncComponent(() => import('@components/PaletteBuilder/index.vue'))
const PaidCommissionSearch = defineAsyncComponent(() => import('@views/office/PaidCommissionSearch.vue'))
const Product = defineAsyncComponent(() => import('@views/item/Product.vue'))
const ProfileForm = defineAsyncComponent(() => import('@views/office/ProfileForm.vue'))
const CustomerRegistrationForm = defineAsyncComponent(() => import('@views/registration/CustomerRegistrationForm.vue'))
const PartiesList = defineAsyncComponent(() => import('@views/office/RetailPartiesPage/PartiesList.vue'))
const SharedCartGenerate = defineAsyncComponent(() => import('@components/SharedCart/Generate.vue'))
const SocialLinksForm = defineAsyncComponent(() => import('@views/office/SocialLinksForm.vue'))
const UpdateBillingForm = defineAsyncComponent(() => import('@views/office/UpdateBillingForm.vue'))
const SelectPartyHost = defineAsyncComponent(() => import('@components/SelectPartyHost.vue'))
const SelfCheckoutForm = defineAsyncComponent(() => import('@components/SelfCheckoutForm.vue'))
const Signature = defineAsyncComponent(() => import('@components/Signature.vue'))

import currencyMixin from '@/js/mixins/currency.js'
import sessionMixin from '@/js/mixins/session.js'
import App from '@views/App.vue'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

const app = createApp()

const router = createRouter({
	history: createWebHistory('/'),
	routes: [
		{
			path: '/:languageCode/shop/retail/:categoryId/:categorySlug/:itemId?/:itemSlug?/',
			name: 'retail-product-layout',
			component: ProductLayout,
		},
		{
			path: '/:languageCode/shop/office/:categoryId/:categorySlug/:itemId?/:itemSlug?/',
			name: 'office-product-layout',
			component: ProductLayout,
		},
		{
			path: '/:languageCode/shop/enroll/:categoryId/:categorySlug/:itemId?/:itemSlug?/',
			name: 'enroll-product-layout',
			component: ProductLayout,
		},
		{
			path: '/:languageCode/shop/party-:partyId/:categoryId/:categorySlug/:itemId?/:itemSlug?/',
			name: 'party-product-layout',
			component: ProductLayout,
		},
		{
			path: '/:languageCode/shop/host/party-:partyId/:categoryId/:categorySlug/:itemId?/:itemSlug?/',
			name: 'host-product-layout',
			component: ProductLayout,
		},

		{
			path: '/:languageCode/office/introducing-seint',
			component: IntroducingSeintLayout,
			children: [
				{
					path: 'timeline',
					name: 'introducing-seint-timeline',
					component: IntroducingSeintTimeline,
				},
				{
					path: 'recognition',
					name: 'introducing-seint-recognition',
					component: IntroducingSeintRecognition,
				},
				{
					path: 'marketing',
					name: 'introducing-seint-marketing',
					component: IntroducingSeintMarketing,
				},
				{
					path: 'faq',
					name: 'introducing-seint-faq',
					component: IntroducingSeintFAQ,
				},
				{
					path: '',
					name: 'introducing-seint-intro',
					component: IntroducingSeintIntro,
				},
				{
					path: '/:languageCode/office/introducing-seint/(.*)*',
					redirect: { name: 'introducing-seint-intro' },
				},
			],
		},
	],

	scrollBehavior(to, from, savedPosition) {
		if (to?.name?.includes('introducing-seint-')) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		}
	},
})

const pinia = createPinia()
app.use(pinia)
app.use(VScrollLock)
app.use(VueDragscroll)
app.use(VueSignaturePad)
app.use(VueTheMask)

app.component('app', App)
app.component('checkbox-input', CheckboxInput)
app.component('headless-accordion', HeadlessAccordion)
app.component('headless-modal', HeadlessModal)
app.component('radio-input', RadioInput)
app.component('anchor-scroll', AnchorScroll)
app.component('braintree', Braintree)
app.component('carousel', Carousel)
app.component('contact-form', ContactForm)
app.component('email-subscribe', EmailSubscribe)
app.component('email-unsubscribe-form', EmailUnsubscribeForm)
app.component('error-messages', ErrorMessages)
app.component('event-registration-edit-form', EventRegistrationEditForm)
app.component('event-registration-form', EventRegistrationForm)
app.component('form-address', FormAddress)
app.component('generic-modal', GenericModal)
app.component('hero-banner', HeroBanner)
app.component('horizontal-cta-items', HorizontalCtaItems)
app.component('humanize-relative-date', HumanizeRelativeDate)
app.component('image-upload', ImageUpload)
app.component('svg-vue', SvgVue)
app.component('label-tag', LabelTag)
app.component('lazy-paginator', LazyPaginator)
app.component('loader', Loader)
app.component('market-choice', MarketChoice)
app.component('product-layout', ProductLayout)
app.component('add-payment-method-form', AddPaymentMethodForm)
app.component('edit-address-form', EditAddressForm)
app.component('edit-dob-form', EditDobForm)
app.component('edit-email-form', EditEmailForm)
app.component('edit-login-form', EditLoginForm)
app.component('edit-name-form', EditNameForm)
app.component('edit-payment-method-form', EditPaymentMethodForm)
app.component('edit-phone-form', EditPhoneForm)
app.component('edit-tax-name-form', EditTaxNameForm)
app.component('verify-address-modal', VerifyAddressModal)
app.component('add-to-bag', AddToBag)
app.component('continue-btn', ContinueBtn)
app.component('item-options', ItemOptions)
app.component('item-selection', ItemSelection)
app.component('return-message', ReturnMessage)
app.component('price-info', PriceInfo)
app.component('reward-modal', RewardModal)
app.component('rewards-program', RewardsProgram)
app.component('rewards-program-table', RewardsProgramTable)
app.component('print-me', PrintMe)
app.component('product-list', ProductList)
app.component('product-list-card', ProductListCard)
app.component('product-list-form', ProductListForm)
app.component('product-list-header', ProductListHeader)
app.component('product-list-items', ProductListItems)
app.component('product-list-login-form', ProductListLoginForm)
app.component('product-list-choice', ProductListChoice)
app.component('product-list-search', ProductListSearch)
app.component('product-list-temp-bulk-add', ProductListTempBulkAdd) // TODO: can delete when ready
app.component('meili-artist-search', MeiliArtistSearch)
app.component('meili-product-search', MeiliProductSearch)
app.component('meili-product-list-search', MeiliProductListSearch)
app.component('market-currency', MarketCurrency)
app.component('media-carousel', MediaCarousel)
app.component('order-confirmation', OrderConfirmation)
app.component('pay-later-message', PayLaterMessage)
app.component('super-key', SuperKey)
app.component('switch-input', SwitchInput)
app.component('special-taxes', SpecialTaxes)
app.component('subscribe-email', SubscribeEmail)
app.component('shared-cart-modal', SharedCartModal)
app.component('toggleValue', ToggleValue)
app.component('training-videos', TrainingVideos)
app.component('add-to-cart-btn', AddToCartBtn)
app.component('artist-info-card', ArtistInfoCard)
app.component('cart-list', CartList)
app.component('rewards', Rewards)
app.component('rewards-hostess', RewardsHostess)
app.component('rewards-wrapper', RewardsWrapper)
app.component('cart-item-form', CartItemForm)
app.component('cart-items', CartItems)
app.component('checkout-form', CheckoutForm)
app.component('downline-viewer', DownlineViewer)
app.component('enrollment-account-form', EnrollmentAccountForm)
app.component('enrollment-checkout', EnrollmentCheckout)
app.component('enrollment-kit-selection', EnrollmentKitSelection)
app.component('item-quantity', ItemQuantity)
app.component('manage-party-form', ManagePartyForm)
app.component('palette-builder', PaletteBuilder)
app.component('paid-commission-search', PaidCommissionSearch)
app.component('product', Product)
app.component('profile-form', ProfileForm)
app.component('customer-registration-form', CustomerRegistrationForm)
app.component('parties-list', PartiesList)
app.component('shared-cart-generate', SharedCartGenerate)
app.component('social-links-form', SocialLinksForm)
app.component('update-billing-form', UpdateBillingForm)
app.component('select-party-host', SelectPartyHost)
app.component('self-checkout-form', SelfCheckoutForm)
app.component('signature', Signature)

app.mixin(currencyMixin)
app.mixin(sessionMixin)

app.config.globalProperties.$toast = toast
const assetBucketBaseUrl = import.meta.env.VITE_ASSET_BUCKET_BASE_URL
app.config.globalProperties.$assetBucketBaseUrl = assetBucketBaseUrl

app.directive('click-outside', {
	mounted(el, binding) {
		el.clickOutsideEvent = function (event) {
			if (!(el === event.target || el.contains(event.target))) {
				binding.value(event, el)
			}
		}
		document.body.addEventListener('click', el.clickOutsideEvent)
	},
	unmounted(el) {
		document.body.removeEventListener('click', el.clickOutsideEvent)
	},
})

app.use(router)
app.mount('#app')
