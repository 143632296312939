<template>
	<div>
		<nav class="in-page-nav">
			<router-link
				v-for="page in pages"
				:to="{
					name: page.routeName,
				}"
				:key="page.routeName"
				:title="`${page.title ? `Seint ${page.title}` : 'Introducing Seint'}`"
				class="in-page-nav--link"
				>{{ page.title || 'Intro' }}</router-link
			>
		</nav>
		<router-view></router-view>
	</div>
</template>

<script>
const introducingSeintPages = [
	{
		title: '',
		routeName: 'introducing-seint-intro',
	},
	{
		title: 'Timeline',
		routeName: 'introducing-seint-timeline',
	},
	{
		title: 'Marketing',
		routeName: 'introducing-seint-marketing',
	},
	{
		title: 'Recognition',
		routeName: 'introducing-seint-recognition',
	},
	{
		title: 'FAQ',
		routeName: 'introducing-seint-faq',
	},
]

export default {
	// props: {
	// },

	data() {
		return {
			pages: introducingSeintPages,
			currentPage: introducingSeintPages[0],
		}
	},
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.in-page-nav {
	margin-bottom: 32px;
}
</style>
