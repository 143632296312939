<template>
	<p :class="isSlider ? 'price' : 'product--price'">
		<template v-if="range">
			<market-currency class="currency price" :amount="range.min" display-free-if-zero display-without-zeros /> -
			<market-currency class="currency price" :amount="range.max" display-free-if-zero display-without-zeros />
		</template>
		<template v-else>
			<market-currency class="currency price" :amount="price" display-free-if-zero display-without-zeros />
		</template>
		<market-currency
			v-if="original"
			class="currency text-line-through original"
			:amount="original"
			display-without-zeros
		/>
		<span v-if="savings" class="currency savings">
			<template v-if="savings.isPaletteCredit">
				<market-currency class="currency" :amount="savings.amount" display-without-zeros /> {{ savings.discountLabel }}
			</template>
			<template v-else-if="savings.isUserSelected">
				{{ savings.discountLabel }}
			</template>
			<template v-else-if="savings.isPercent"> {{ savings.amount }}% Off </template>
			<template v-else>
				Save <market-currency class="currency" :amount="savings.amount" display-without-zeros />
			</template>
		</span>
	</p>
</template>
<script>
export default {
	props: {
		item: {
			type: Object,
			required: true,
		},
		minPrice: {
			type: Number,
			default: null,
		},
		maxPrice: {
			type: Number,
			default: null,
		},
		saleInformation: {
			type: Array,
		},
		isCartItem: {
			type: Boolean,
			default: false,
		},
		isSlider: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		sale() {
			const saleArr = this.saleInformation ?? []
			return saleArr.find((sale) => sale.qualifying_skus.includes(this.item.itemCode) && sale.can_purchase)
		},
		range() {
			if (this.minPrice && this.maxPrice && this.minPrice !== this.maxPrice) {
				return { min: this.minPrice, max: this.maxPrice }
			}
			return null
		},
		price() {
			const { priceRaw } = this.item

			if (this.sale) {
				const { credit, type } = this.sale

				let discountAmount
				if (type === 'percent') {
					discountAmount = (priceRaw * credit) / 100
				} else {
					discountAmount = credit
				}

				return Math.max(0, priceRaw - discountAmount)
			}

			if (this.isCartItem && this.item.discounted) {
				const { discountType, discountValue } = this.item

				let amount
				if (discountType === 'percent') {
					amount = (priceRaw * (100 - discountValue)) / 100
				} else {
					amount = priceRaw - discountValue
				}

				return Math.max(0, amount)
			}

			return priceRaw
		},
		original() {
			const { isBundle, bundleValue, priceRaw, isReleasedForCustomer } = this.item

			if (this.item?.isAutoAdd) return null

			if (!isReleasedForCustomer) return null

			if ((this.isCartItem && this.item.discounted) || this.sale) {
				// safegaurd 0 discount case
				if (parseInt(priceRaw) === this.price) {
					return null
				}
				return priceRaw
			}
			if (isBundle && parseFloat(bundleValue) > 0) {
				return bundleValue
			}
			return null
		},
		savings() {
			const { isBundle, bundleValue, priceRaw, isReleasedForCustomer } = this.item

			if (!isReleasedForCustomer) return null

			const isPromotional = !!(this.item?.isPromotionalItem || this.sale?.is_promotion)
			const isPaletteCredit = !!(this.item?.discountId === 'palette_credit' && this.isCartItem)
			const discountLabel = isPaletteCredit ? this.item?.discountTitle : this.item?.discountText

			if (this.sale) {
				const { credit, type } = this.sale

				let discountAmount
				if (type === 'percent') {
					discountAmount = (priceRaw * credit) / 100
				} else {
					discountAmount = credit
				}

				// safegaurd 0 discount case
				if (discountAmount === 0) return null

				return {
					isPercent: isPromotional && type === 'percent',
					isAmount: !isPromotional || type !== 'percent',
					isBundle: false,
					amount: !isPromotional ? discountAmount : Math.floor(credit),
					isPaletteCredit,
					discountLabel,
				}
			}

			if (this.isCartItem && this.item.discounted) {
				const { discountType, discountValue, isUserSelected } = this.item

				let discountAmount
				if (discountType === 'percent') {
					discountAmount = (priceRaw * discountValue) / 100
				} else {
					discountAmount = discountValue
				}

				return {
					isPercent: isPromotional && discountType === 'percent',
					isAmount: !isPromotional || discountType !== 'percent',
					isBundle: false,
					amount: !isPromotional ? discountAmount : Math.floor(discountValue),
					isPaletteCredit,
					isUserSelected,
					discountLabel,
				}
			}

			if (isBundle && parseFloat(bundleValue) > 0) {
				return { isBundle, amount: parseFloat(bundleValue) - priceRaw }
			}
			return null
		},
	},
}
</script>
<style lang="scss" scoped>
.product--price {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.price {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
.price {
	font-weight: 510;
	font-size: 13px;
	line-height: 16px;
	color: #000000;
}
.original {
	font-weight: 400;
	font-size: 11px;
	line-height: 16px;
	color: #777777;
	margin-left: 2px;
}
.savings {
	font-weight: 400;
	font-size: 11px;
	line-height: 16px;
	color: #9e3501;
	margin-left: 2px;
}
</style>
