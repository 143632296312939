// modal on Recreate The Look page used for viewing embed video
// $('#recreate-the-look-modal').on('show.bs.modal', () => {
//     const viewportWidth = window.innerWidth
//     if (viewportWidth < 768) {
//         const video = document.getElementById("recreate-the-look-video")
//         if (video.requestFullscreen) {
//             video.requestFullscreen()
//         } else if (video.mozRequestFullScreen) {
//             video.mozRequestFullScreen()
//         } else if (video.webkitRequestFullscreen) {
//             video.webkitRequestFullscreen()
//         } else if (video.msRequestFullscreen) {
//             video.msRequestFullscreen()
//         }
//     }
// })
$('#recreate-the-look-modal').on('hidden.bs.modal', () => {
	document.getElementById('recreate-the-look-video').pause()
})
