<template>
	<div class="container">
		<div class="responsive-video-container">
			<div class="responsive-video">
				<iframe
					src="https://player.vimeo.com/video/473586586"
					width="auto"
					height="auto"
					frameborder="0"
					allow="autoplay; fullscreen"
					allowfullscreen
				></iframe>
			</div>
		</div>
		<div class="sm-container wrap">
			<h1 class="mb-0 text-center">The Seint Story</h1>
			<p>
				Cara Brook, our founder and CEO, was born to be an innovator. At 11 years old, she could tell that there was a
				problem with the beauty industry. She tackled this problem with a 5th grade science fair project that aimed to
				simplify the beauty routine. Even though the project itself was a bust, she never gave up on the idea that the
				beauty industry could be revolutionized. Years later, as the creator of the beauty blog Maskcara, she could see
				that instead of making women feel more beautiful, the beauty industry had a tendency to make them feel more
				insecure by convincing them that they needed more products and more rules to abide by in order to attempt an
				impossible standard. She knew she wanted to create something no one had done before. Her goals were to celebrate
				every type of natural beauty, share simple and useful tips, and create quality, innovative products that could
				be used by every woman, no matter her age or complexion or level of experience.
			</p>
			<p>
				In 2013, Maskcara Beauty was born. Founded on the principle that “helping others look beautiful is nice, but
				helping them believe they are beautiful is life-changing,” our IIID products were created to redefine, simplify,
				and streamline the beauty routine. Armed with determination in heart and customizable palette in hand, the
				makeup revolution had begun. This uniquely intuitive and personalized approach has inspired thousands of women
				to recognize and celebrate their own beautiful, gain confidence, and radiate positivity. We introduced the
				Artist program in 2017, bringing the opportunity to “have your cake and eat it, too” to those who caught the
				vision and felt inspired to share Maskcara’s products and vision with the world.
			</p>
			<p>
				As the Maskcara brand has grown, we have seen the way that beauty truly connects all of us to each other.
				Recognizing and enhancing the beauty that already exists in every face has always been our inspiration, so we
				are thrilled to announce that in 2020 Maskcara Beauty is changing its name to Seint to better reflect its
				purpose. This change is inspired by our belief that seeking, experiencing, and cultivating beauty is a sacred
				work; the work of saints. Saints aren’t made or created; they are simply recognized for the good they bring to
				the world. In a similar way, we believe that we don’t just make or create beauty; we recognize, nurture, and
				preserve it. Like all good things, beauty matters. Spelled in the traditional old French to honor the intrinsic
				value of the word, Seint represents our dedication to helping others look beautiful and believe they are
				beautiful. Because beauty is not our creation; it is our passion.
			</p>

			<h2 class="mb-0 text-center h1">Message from Cara</h2>
			<figure class="unwrap" role="figure" aria-label="Cara Brook">
				<img :src="`${$assetBucketBaseUrl}/backoffice/demi/the-demi-story.jpg`" alt="Cara Brook" />
			</figure>
			<p class="typography-callout">
				I am inspired by every single face I see; they each have such unique and stunning features. I created this
				company as a way to share with women that they are beautiful and help them to recognize their beauty and see it
				in others. Every product is designed with that in mind.
			</p>

			<p class="typography-callout">
				For generations, women have been hiding or covering up things they love about their faces in the pursuit of
				concealing things they don’t. What if we could show you a way to enhance the features you love and diminish the
				ones you don’t without sacrificing any of the natural beauty that makes you, you? Your face is a roadmap of your
				life and we believe it deserves to be valued and celebrated. You really can have your cake and eat it, too!
			</p>

			<p class="typography-callout">
				At Seint, we are beauty conservationists. Just as a museum would care for and preserve a beautiful work of art,
				we see makeup as a vital way to care for and preserve the story of your life that you wear each day on your
				beautiful face. Beauty connects us to each other; and our passion is helping you to find the beauty in yourself
				and the world around you.
			</p>

			<p class="typography-callout">Thank you so much for being here. You are beautiful!</p>
			<p class="typography-callout">XO - Cara Brook</p>

			<h2 class="text-center h1">Mission Statement</h2>
			<p class="text-center">
				Beauty connects us.
				<br />It transcends time and knows no boundaries. <br />Seeking, experiencing, and cultivating beauty in
				ourselves and the world around us is a sacred work. <br />And that work—our work—is beautiful. <br />We see
				beauty in every face. <br />Our mission is to preserve and nurture it. <br />Everything we do and everything we
				create is based on what we believe in: <br />That beauty matters. <br />That helping others look beautiful is
				nice, <br />But helping them believe they are beautiful is life changing. <br />Beauty is not our creation;
				<br />Beauty is our passion.
			</p>
		</div>
	</div>
</template>

<script>
// export default {
//   props: {
//   },
// }
</script>

<style lang="scss" scoped>
@import '../../../sass/unishop/helpers/_mixins';
@import '../../../sass/unishop/helpers/_variables';

.in-page-nav + .container {
	@include bp($content-max-width, to) {
		padding-left: 0;
		padding-right: 0;
	}
}

figure {
	margin-top: 16px;

	img {
		margin-left: auto;
		margin-right: auto;
		width: 100%;
	}

	img {
		max-width: 580px; // $container-sm-max-width - x padding
	}

	+ p,
	+ h1,
	+ h1,
	+ h1 {
		margin-top: 20px;
	}
}

.responsive-video-container {
	+ .wrap {
		margin-top: 32px;
	}
}

h1 {
	~ h2 {
		margin-top: 32px;

		@include bp(lg-up) {
			margin-top: 56px;
		}
	}
}

h2 {
	+ p {
		margin-top: 12px;
	}
}
</style>
