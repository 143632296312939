import { defineStore } from 'pinia'
import axios from 'axios'

export const useReturnsStore = defineStore('returns', {
	state: () => ({
		loading: false,
		error: null,
		step: '',
		selected: {},
		items: {},
		options: {},
		disabledOverride: false,
		collectionsSelected: {},
		collectionsOptions: {},
		paymentMethod: null,
		deliveryAddress: null,
		paymentAgree: false,
		returnMethod: null,
	}),

	getters: {
		disabled: (state) => {
			switch (state.step) {
				case 'selection':
					const selectedItems = Object.fromEntries(Object.entries(state.selected).filter(([, val]) => !!val))
					if (Object.keys(selectedItems).length === 0) return true
					for (const [key] of Object.entries(selectedItems)) {
						if (state.items[key] && !state.items[key].reasonId) {
							return true
						}
					}
					return false
				case 'options':
					if (state.disabledOverride) return state.disabledOverride
					const potentialOptions = Object.values(state.options).map(({ returnOption, isCollection }) => {
						if (isCollection) {
							const collectionItemKeys = Object.keys(state.collectionsSelected).filter(
								(k) => state.collectionsSelected[k]
							)
							for (const key of collectionItemKeys) {
								if (key in state.collectionsOptions) {
									return returnOption.agreement // make sure they agree too
								}
							}
						}
						if (Object.values(returnOption).length === 0) return false
						return Object.values(returnOption).every(Boolean)
					})
					return !potentialOptions.every(Boolean)
				case 'payment':
					if (!state.paymentMethod) return true
					if (!state.deliveryAddress) return true
					if (!state.paymentAgree) return true
					return false
				case 'label':
					if (!state.returnMethod) return true
					return false
				default:
					return true
			}
		},
	},

	actions: {
		// step helper
		setStep(step) {
			this.step = step
		},
		preparePayload() {
			switch (this.step) {
				case 'selection':
					const keys = Object.keys(this.selected).filter((key) => this.selected[key])
					const selections = []
					for (const key of keys) {
						selections.push(this.items[key])
					}
					return { selections }
				case 'options':
					const options = Object.values(this.options).map(
						({ itemCode, itemId, isCollection, itemDescription, orderDetailId, returnOption }) => ({
							itemCode,
							itemId,
							isCollection,
							orderDetailId,
							itemDescription,
							...returnOption,
						})
					)
					const payload = []

					for (const option of options) {
						const { itemCode, itemId, isCollection, orderDetailId, type } = option
						if (type === 'both') continue // omit this (should be blocked though)

						const isRefund = type === 'refund'
						const isShadeSwap = type === 'shadeSwap'
						const isReplace = type === 'replace'

						if (isCollection) {
							// get applicable swap keys
							const keys = Object.keys(this.collectionsOptions).filter((key) => key.includes(itemCode))

							const collectionItems = []
							for (const key of keys) {
								const [, originalItem, replacement] = key.split(':')
								const [, parentItemId] = originalItem.split('-')
								const [replacementItemCode, replacementItemId] = replacement.split('-')
								/**
								 * itemCode - originalItemCode
								 * itemId - original
								 * replacementItemCode - replace or swap
								 * parentItemId - collection only (if child is being swapped or replaced)
								 */
								const nestedObj = {
									isRefund,
									isShadeSwap,
									isReplace,
									isCollection,
									orderDetailId,
									parentItemId: parseInt(parentItemId),
									itemCode: `${replacementItemCode}`,
									itemId: parseInt(replacementItemId),
									replacementItemCode: `${replacementItemCode}`, // prepped for replacement by default
								}
								if (isShadeSwap) {
									const selectedReplacementItemCode = this.collectionsOptions[key].itemCode
									nestedObj.replacementItemCode = `${selectedReplacementItemCode}` // replace with selected shade swap
								}
								collectionItems.push(nestedObj)
							}
							if (collectionItems.length > 0) {
								payload.push(...collectionItems)
							}
						} else {
							const obj = {
								isRefund,
								isShadeSwap,
								isReplace,
								isCollection,
								orderDetailId,
								itemCode: `${itemCode}`,
								itemId: parseInt(itemId),
								replacementItemCode: null,
								parentItemId: null,
							}
							if (isShadeSwap) {
								const { shadeSwap } = option
								obj.replacementItemCode = `${shadeSwap.itemCode}`
							} else if (isReplace) {
								obj.replacementItemCode = `${itemCode}`
							}
							payload.push(obj)
						}
					}
					return { selections: payload }
				case 'payment':
					return { paymentMethod: this.paymentMethod, deliveryAddress: this.deliveryAddress }
				case 'label':
					return { returnMethod: this.returnMethod }
				default:
					return null
			}
		},
		// step 1
		addItem(key, item) {
			if ('reasonId' in item) {
				this.selected[key] = true
			} else {
				this.selected[key] = false
			}
			this.items[key] = { reasonId: '', notes: '', ...item }
		},
		setSelected(key, bool) {
			this.selected[key] = bool
		},
		// step 2
		addOption(key, item) {
			this.options[key] = { returnOption: {}, ...item }
		},
		toggleReplacePayload({ key, payload }) {
			if (this.collectionsSelected[key]) {
				this.collectionsOptions[key] = payload
			} else {
				delete this.collectionsOptions[key]
			}
		},
		handleOptionsChange(key) {
			if (!this.collectionsSelected[key]) {
				delete this.collectionsOptions[key]
			}
		},
		handleSelectionChange({ key, payload }) {
			if (!this.collectionsSelected[key]) {
				this.collectionsSelected[key] = true
				this.collectionsOptions[key] = payload
			}
		},
		setDisabledOverride(bool) {
			this.disabledOverride = bool
		},
		// step 3 & 4
		setValue(key, value) {
			this[key] = value
		},
		// submissions
		async saveStep(endpoint) {
			try {
				this.error = null
				this.loading = true
				const payload = this.preparePayload()
				const { data: nextUrl } = await axios.post(endpoint, payload)
				window.location.replace(nextUrl)
			} catch (error) {
				this.error = error.response?.data?.message ?? 'An error occured'
				this.loading = false
			}
		},
	},
})
