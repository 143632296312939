import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
	state: () => ({
		isLargeMediaQuery: false,
		searchActive: false,
		pageScrollLock: false,
		mobileMenuActive: false,
		profilePanelActive: false,
		bagPanelActive: false,
		shopMenuItemActive: true,
		accountHeaderItemActive: false,
	}),

	getters: {},

	actions: {
		setLargeMediaQuery() {
			if (this.mobileMenuActive) {
				this.toggleMobileMenu()
			}
			this.isLargeMediaQuery = true
		},
		unsetLargeMediaQuery() {
			this.isLargeMediaQuery = false
		},
		lockPageScroll() {
			this.pageScrollLock = true
		},
		unlockPageScroll() {
			this.pageScrollLock = false
		},
		setBackDrop() {
			this.bagPanelActive = true
			const el = document.getElementById('app-state')
			el.setAttribute('data-state', 'bag-active')
		},
		toggleMobileMenu() {
			if (!this.isLargeMediaQuery && !this.mobileMenuActive) {
				this.shopMenuItemActive = true
			}
			this.lockPageScroll = !this.mobileMenuActive
			this.mobileMenuActive = !this.mobileMenuActive
			const el = document.getElementById('app-state')
			if (this.mobileMenuActive) {
				el.setAttribute('data-state', 'menu-active')
			} else {
				el.removeAttribute('data-state')
			}
		},
		closeMobileMenu() {
			this.mobileMenuActive = false
			const el = document.getElementById('app-state')
			el.removeAttribute('data-state')
		},
		openSearch() {
			this.searchActive = true
			const el = document.getElementById('app-state')
			el.setAttribute('data-state', 'search-active')
		},
		closeSearch() {
			this.searchActive = false
			const el = document.getElementById('app-state')
			el.removeAttribute('data-state')
		},
		toggleSearchActive() {
			this.pageScrollLock = !this.searchActive
			this.searchActive = !this.searchActive
		},
		toggleProfilePanelActive() {
			this.mobileMenuActive = false
			this.lockPageScroll = !this.profilePanelActive
			this.profilePanelActive = !this.profilePanelActive
			const el = document.getElementById('app-state')
			if (this.profilePanelActive) {
				el.setAttribute('data-state', 'profile-active')
			} else {
				el.removeAttribute('data-state')
			}
		},
		toggleBagPanelActive() {
			this.mobileMenuActive = false
			this.lockPageScroll = !this.bagPanelActive
			this.bagPanelActive = !this.bagPanelActive
			const el = document.getElementById('app-state')
			if (this.bagPanelActive) {
				el.setAttribute('data-state', 'bag-active')
			} else {
				el.removeAttribute('data-state')
			}
		},
		openBagPanel() {
			this.mobileMenuActive = false
			this.lockPageScroll = true
			this.bagPanelActive = true
			const el = document.getElementById('app-state')
			el.setAttribute('data-state', 'bag-active')
		},
		toggleShopMenuItem() {
			if (this.accountHeaderItemActive && this.isLargeMediaQuery) {
				this.accountHeaderItemActive = false
			}
			this.shopMenuItemActive = !this.shopMenuItemActive
		},
		setShopMenuItemActive() {
			this.shopMenuItemActive = true
		},
		unsetShopMenuItemActive() {
			this.shopMenuItemActive = false
		},
		toggleAccountHeaderItem() {
			if (this.shopMenuItemActive && this.isLargeMediaQuery) {
				this.shopMenuItemActive = false
			}
			this.accountHeaderItemActive = !this.accountHeaderItemActive
		},
		setAccountHeaderItemActive() {
			this.accountHeaderItemActive = true
		},
		unsetAccountHeaderItemActive() {
			this.accountHeaderItemActive = false
		},
	},
})
